import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTransition, animated } from 'react-spring';
import '../App.css';
import { FaArrowCircleUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';


import NavbarBlog from '../components/navbarBlog.tsx';
import Footer from '../components/footer.tsx';

import usePreventZoom from '../preventZoom.js';

import Header from './blog/header.tsx';
import BlogView from './blog/blogView.tsx';

import BG_SAFMC from '../assets/Blog/SAFMC/BG_SAFMC.webp';

import BG_SAFMC_PART1 from "../assets/Blog/SAFMC/SAFMC_PART1/HEADER_SAFMC_PART1.webp";
import BG_SAFMC_PART2 from "../assets/Blog/SAFMC/SAFMC_PART2/HEADER_SAFMC_PART2.webp";
import BG_SAFMC_PART3 from "../assets/Blog/SAFMC/SAFMC_PART3/HEADER_SAFMC_PART3.webp";
import BG_SAFMC_PART4 from "../assets/Blog/SAFMC/SAFMC_PART4/HEADER_SAFMC_PART4.webp";
import BG_SAFMC_PART5 from "../assets/Blog/SAFMC/SAFMC_PART5/HEADER_SAFMC_PART5.webp";
import BG_SAFMC_PART6 from "../assets/Blog/SAFMC/SAFMC_PART6/HEADER_SAFMC_PART6.webp";
import BG_SAFMC_PART7 from "../assets/Blog/SAFMC/SAFMC_PART7/HEADER_SAFMC_PART7.webp";


export default function Blog() {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 900px)').matches);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(30);
        setTimeout(() => {
            setProgress(100);
        }, 600);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 900px)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 350) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const arrowTransition = useTransition(showScrollToTop, {
        from: { opacity: 0, transform: 'translateY(100%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(100%)' },
        config: { tension: 300, friction: 20 },
      });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: false});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    usePreventZoom();

    const SAFMC_PART1 = {
        postUrl: 'https://rifqifarhansyah.id/',
        imageUrl: BG_SAFMC_PART1,
        title: '2 Months Preparation for SAFMC 2023 (Part 1)',
        description: 'It has always been my dream to participate in an international competition where individuals from various countries gather to discuss and present their work with the aim of achieving victory...',
        category: 'SAFMC 2023',
        authorUrl: 'https://www.rifqifarhansyah.id/',
        authorName: 'Mohammad Rifqi Farhansyah',
        dateDay: 21,
        dateMonth: 'Dec',
        backgroundColor:"bg-[#f9ad40]",
        textColor:"text-[#f9ad40]",
        categoryTop:"top-56",
        postId:1,
    };

    const SAFMC_PART2 = {
        postUrl: 'https://rifqifarhansyah.id/',
        imageUrl: BG_SAFMC_PART2,
        title: 'My First Departure Abroad (Part 2)',
        description: 'After long preparation, from registering my self to join ITB`s SAFMC team 2023, learn a lot of new thing, and preparing departure, it was the showtime...',
        category: 'SAFMC 2023',
        authorUrl: 'https://www.rifqifarhansyah.id/',
        authorName: 'Mohammad Rifqi Farhansyah',
        dateDay: 23,
        dateMonth: 'Dec',
        backgroundColor:"bg-[#f9ad40]",
        textColor:"text-[#f9ad40]",
        categoryTop:"top-48",
        postId:2,
    };

    const SAFMC_PART3 = {
        postUrl: 'https://rifqifarhansyah.id/',
        imageUrl: BG_SAFMC_PART3,
        title: 'D1 - Semi Autonomous Drone Competition (Part 3)',
        description: 'Tomorrow is quite a tense day for us as we will be participating in the competition in the D1 category – Semi Autonomous Drone at the Science Center, Singapore...',
        category: 'SAFMC 2023',
        authorUrl: 'https://www.rifqifarhansyah.id/',
        authorName: 'Mohammad Rifqi Farhansyah',
        dateDay: 24,
        dateMonth: 'Dec',
        backgroundColor:"bg-[#f9ad40]",
        textColor:"text-[#f9ad40]",
        categoryTop:"top-48",
        postId:3,
    };

    const SAFMC_PART4 = {
        postUrl: 'https://rifqifarhansyah.id/',
        imageUrl: BG_SAFMC_PART4,
        title: 'D2 - Autonomous Drone Competition (Part 4)',
        description: 'Yesterday was a quite long day, but the most important thing is that we only need to focus on the D2 – Autonomous Drone category that will take place today...',
        category: 'SAFMC 2023',
        authorUrl: 'https://www.rifqifarhansyah.id/',
        authorName: 'Mohammad Rifqi Farhansyah',
        dateDay: 24,
        dateMonth: 'Dec',
        backgroundColor:"bg-[#f9ad40]",
        textColor:"text-[#f9ad40]",
        categoryTop:"top-48",
        postId:4,
    };

    const SAFMC_PART5 = {
        postUrl: 'https://rifqifarhansyah.id/',
        imageUrl: BG_SAFMC_PART5,
        title: 'Spend Some Days in Batam (Part 5)',
        description: 'Those tense days have come to an end, and now we are lazyly awaiting the announcement of the results in three days...',
        category: 'SAFMC 2023',
        authorUrl: 'https://www.rifqifarhansyah.id/',
        authorName: 'Mohammad Rifqi Farhansyah',
        dateDay: 24,
        dateMonth: 'Dec',
        backgroundColor:"bg-[#f9ad40]",
        textColor:"text-[#f9ad40]",
        categoryTop:"top-48",
        postId:5,
    };

    const SAFMC_PART6 = {
        postUrl: 'https://rifqifarhansyah.id/',
        imageUrl: BG_SAFMC_PART6,
        title: 'Walk Around Singapore - Hello Merlion! (Part 6)',
        description: 'Singapore today feels different from the Singapore I visited a few days ago. Perhaps it`s because the pressure of the competition is no longer weighing on us...',
        category: 'SAFMC 2023',
        authorUrl: 'https://www.rifqifarhansyah.id/',
        authorName: 'Mohammad Rifqi Farhansyah',
        dateDay: 24,
        dateMonth: 'Dec',
        backgroundColor:"bg-[#f9ad40]",
        textColor:"text-[#f9ad40]",
        categoryTop:"top-48",
        postId:6,
    };

    const SAFMC_PART7 = {
        postUrl: 'https://rifqifarhansyah.id/',
        imageUrl: BG_SAFMC_PART7,
        title: 'Awarding Ceremony, "Thanks God" (Part 7)',
        description: 'Today is the long-awaited-day. The outcome of all the dedicated efforts we have put in over the past two months will be determined today...',
        category: 'SAFMC 2023',
        authorUrl: 'https://www.rifqifarhansyah.id/',
        authorName: 'Mohammad Rifqi Farhansyah',
        dateDay: 24,
        dateMonth: 'Dec',
        backgroundColor:"bg-[#f9ad40]",
        textColor:"text-[#f9ad40]",
        categoryTop:"top-48",
        postId:7,
    };

    return (
        <div className="App">
            <NavbarBlog />
            <div className='pt-20 min-w-screen'>
                {/* Loading Bar */}
                <LoadingBar color="#8C001f" progress={progress} onLoaderFinished={() => setProgress(0)} />
                <section className='hidden w-64 mr-10 group transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out'>
                        <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=e1e1e1&w=a&t=tt&d=mIBEHA96Sa9wc29y0yefyCb9wP8YgephePOwDaQRDk0&co=ffffff&cmo=8c001f&cmn=d4201e&ct=394048'></script>
                </section>
                <span id="safmc">
                    <Header backgroundImage={BG_SAFMC} title="Singapore Amazing Flying Machine Competition 2023" backgroundPosition="left bottom"/>
                </span>
                <div id='safmc-after-movie' className='font-condensed text-textGrey md:text-4xl text-3xl font-bold mb-2 mt-8'>
                    AFTER MOVIE
                    <p className='text-lg font-extralight'>Recorded by: <a href="https://www.youtube.com/@AksantaraITB" className='text-textRed'>Aksantara ITB</a></p>
                </div>
                <div className='w-full flex sm:flex-col lg:flex-row flex-col justify-center items-center mb-16'>
                    <div className='afterMovie-container w-[22rem] sm:w-[44rem] md:w-[48rem] lg:w-[54rem] flex md:flex md:flex-row flex-col justify-center items-center'>
                        <div className="embedded-container aspect-w-16 aspect-h-9 w-11/12">
                            <iframe
                            className="video-iframe w-full h-full rounded-xl"
                            src={`https://www.youtube.com/embed/89qinqi1YY0`}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div id='safmc-story-telling' className='font-condensed text-textGrey md:text-4xl text-3xl font-bold mt-8'>
                    STORYTELLING BLOG
                </div>
                <div className='my-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-2 gap-y-4 justify-items-center'>
                    <Link to="/safmc/safmc-part1">
                        <BlogView {...SAFMC_PART1} />
                    </Link>
                    <Link to="/safmc/safmc-part2">
                        <BlogView {...SAFMC_PART2} />
                    </Link>
                    <Link to="/safmc/safmc-part3">
                        <BlogView {...SAFMC_PART3} />
                    </Link>
                    <Link to="/safmc/safmc-part4">
                        <BlogView {...SAFMC_PART4} />
                    </Link>
                    <Link to="/safmc/safmc-part5">
                        <BlogView {...SAFMC_PART5} />
                    </Link>
                    <Link to="/safmc/safmc-part6">
                        <BlogView {...SAFMC_PART6} />
                    </Link>
                    <Link to="/safmc/safmc-part7">
                        <BlogView {...SAFMC_PART7} />
                    </Link>
                </div>
            </div>
            <Footer />
            {arrowTransition((style, item) =>
                item ? (
                <animated.div
                    style={style}
                    className="fixed bottom-5 right-5 cursor-pointer z-10"
                    onClick={scrollToTop}
                >
                    <FaArrowCircleUp
                    size={isMd ? 32 : 24}
                    className="text-[#c0c5ca] hover:scale-110"
                    />
                </animated.div>
                ) : null
            )}
        </div>
    );
}
