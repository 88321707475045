import React from 'react';

interface HeaderProps {
  backgroundImage: string;
  title: string;
  date: string;
  year: string;
  backgroundPosition?: string;
}

const HeaderDetailBlog: React.FC<HeaderProps> = ({ backgroundImage, title, date, year, backgroundPosition = 'center center' }) => {
    const headerStyle: React.CSSProperties = {
        background: `url(${backgroundImage}) no-repeat`,
        backgroundPosition,
        backgroundSize: 'cover',
    };

    const whiteBackgroundStyle: React.CSSProperties = {
        backgroundColor: 'rgba(255, 255, 255, 0.35)', // 25% opacity white
    };

    return (
        <header style={headerStyle} className='bg-fixed flex items-center justify-center h-96 font-condensed'>
            <div style={whiteBackgroundStyle} className='bg-white h-auto w-full p-4 mt-24 text-center md:flex md:flex-row flex flex-col justify-between px-20'>
                <div className='flex flex-col'>
                    <p className='text-2xl font-bold opacity-70 text-black'>{date}</p>
                    <p className='text-md opacity-70 text-black'>{year}</p>
                </div>
                <p className="text-4xl font-bold opacity-70 text-black self-center">{title}</p>
            </div>
        </header>
    );
};

export default HeaderDetailBlog;
