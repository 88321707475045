import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const ImageModal = ({ showModal, closeModal, imageUrl, imageAlt }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = imageUrl;
  }, [imageUrl]);

  return (
    <div className={`modal ${showModal ? 'opacity-100 visible' : 'opacity-0 invisible'} fixed inset-0 overflow-hidden bg-black bg-opacity-75 z-50 px-4 mt-20 sm:mt-20 transition-opacity duration-300 ease-in-out transition-visible duration-300 ease-in-out flex flex-col justify-center items-center`}>
      <div className={`max-h-full flex flex-col items-end md:mt-10 mt-0 transition-transform duration-300 ease-in-out ${showModal ? 'transform scale-100' : 'transform scale-0'}`}>
        <span className="close text-2xl my-2 text-white cursor-pointer hover:scale-125" onClick={closeModal}>
          <FaTimes />
        </span>
        {!imageLoaded ? (
          <div className="flex justify-center items-center h-5/6 mx-auto">
            <div className="spinner border-4 border-t-4 border-gray-200 h-12 w-12 rounded-full animate-spin"></div>
          </div>
        ) : (
          <img className="md:object-scale-down object-contain h-5/6 mx-auto" src={imageUrl} alt={imageAlt} />
        )}
        <div id="caption" className="text-center text-condensed md:text-md text-xs text-bold text-white mt-2 mx-auto">{imageAlt}</div>
      </div>
    </div>
  );
};

export default ImageModal;
