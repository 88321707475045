import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTransition, animated } from 'react-spring';
import { FaArrowCircleUp, FaAngleDoubleLeft, FaAngleDoubleRight, FaEye } from 'react-icons/fa';
import NavbarDetailBlog from '../../../components/navbarDetailBlog.tsx';
import HeaderDetailBlog from '../utils/headerDetailBlog.tsx';
import Footer from '../../../components/footer.tsx';
import { Link } from 'react-router-dom';
import ImageModal from '../utils/imageModal.tsx';


import usePreventZoom from '../../../preventZoom.js';

import Header from '../../../assets/Blog/SAFMC/SAFMC_PART1/Detail/HEADER_DETAIL_SAFMC_PART1.webp';
import Pict1 from "../../../assets/Blog/SAFMC/SAFMC_PART1/Detail/1.webp";
import Pict2 from "../../../assets/Blog/SAFMC/SAFMC_PART1/Detail/2.webp";
import Pict3 from "../../../assets/Blog/SAFMC/SAFMC_PART1/Detail/3.webp";
import Pict4 from "../../../assets/Blog/SAFMC/SAFMC_PART1/Detail/4.webp";
import Pict5 from "../../../assets/Blog/SAFMC/SAFMC_PART1/Detail/5.webp";

import CommentListExample from '../../../components/comments/commentListExample.tsx';

const SAFMCPART1: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 900px)').matches);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedAlt, setSelectedAlt] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(30);
        setTimeout(() => {
            setProgress(100);
        }, 600);
    }, []);
  
    const openModal = (imageUrl, imageAlt) => {
        setSelectedImage(imageUrl);
        setSelectedAlt(imageAlt);
        setModalVisible(true);
    };
  
    const closeModal = () => {
        setSelectedImage('');
        setSelectedAlt('');
        setModalVisible(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 900px)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 350) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const arrowTransition = useTransition(showScrollToTop, {
        from: { opacity: 0, transform: 'translateY(100%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(100%)' },
        config: { tension: 300, friction: 20 },
      });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: false});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    usePreventZoom();

    return(
        <div className='App'>
            <NavbarDetailBlog/>
            <div className='pt-20 min-w-screen font-condensed text-textGrey'>
                {/* Loading Bar */}
                <LoadingBar color="#8C001f" progress={progress} onLoaderFinished={() => setProgress(0)} />
                <section className='hidden w-64 mr-10 group transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out'>
                        <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=e1e1e1&w=a&t=tt&d=mIBEHA96Sa9wc29y0yefyCb9wP8YgephePOwDaQRDk0&co=ffffff&cmo=8c001f&cmn=d4201e&ct=394048'></script>
                </section>
                <HeaderDetailBlog backgroundImage={Header} title="2 Months Preparation for SAFMC 2023" date="21" year="December, 2023"></HeaderDetailBlog>
                <div className='author-article text-end pr-4 pt-8 pb-4 text-xs'>
                    <p className='font-condensed text-textGrey'>by Mohammad Rifqi Farhansyah | Singapore Amazing Flying Machine Competition 2023</p>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-36 mb-20'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict1, 'SAFMC Logo')}>
                            <img
                                src={Pict1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="SAFMC's Logo"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>SAFMC Logo</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 SAFMC</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-2xl'>It has always been my dream to participate in an international competition where individuals from various countries gather to discuss and present their work with the aim of achieving victory. I would be extremely grateful for the opportunity to share my knowledge and contribute to the event.</p>
                        <br/>
                        <p className='text-lg'>On February 7, 2023, at 12:07 WIB, I was enjoying a beautiful day at ITB when suddenly, one of my friend <span className='text-textRed'>Rizky Abdillah Rasyid</span> dropped the news that AKSANTARA (Unmanned Aerial Vehicle Community in ITB) was offering an opportunity for programmers with experiences in OpenCV library and Python to get involved in their annual international competition, SAFMC. While I was familiar with OpenCV and Python, I had no idea how it could be implemented for a drone. Without a second thought, I quickly opened my laptop, fired up my web browser, and started searching for documentation on using OpenCV with drones and details about SAFMC 2023. Then, oh my god! I was shocked that the competition will be held at March 20 - April 1, 2014, just about 2 months away. In fact, I hadn’t prepared anything, except for registering myself to the head of ITB's SAFMC 2023 Team with my friend <span className='text-textRed'>Muchammad Dimas Sakti Widyatmaja</span>. I took a deep breath for a while and hoped that 2 months would be long enough for me. It was surely a great program and a lot of prospective students who interested in UAVs from around Southeast Asia would apply. I was sure that they had prepared a long time enough to make their implementation stood against others.</p>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"I had only about two months to learn everything related to UAVs. Why not? Let's give it a try!"</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Header})`, backgroundSize: 'cover',}}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict2, 'Our hardware equipment for this competition. All of that stuff will be used to build 2 drone.')}>
                            <img
                                src={Pict2}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Our hardware equipment for this competition."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Our hardware equipment for this competition. All of that stuff will be used to build 2 drone.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi | Labtek 8, Institut Teknologi Bandung, Indonesia</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'><i>Friday, 10 February 2023 at 18.37 WIB, </i>Sakti and I officially became members of the ITB's SAFMC team for 2023. Our primary responsibilities involve developing a Python-based system using the OpenCV library for drones. Our goal is to enable the drone to operate autonomously without requiring instructions from a pilot. The main challenge we face is ensuring effective communication and synchronization between our two drones while minimizing communication errors. Despite our roles as programmers in the team, both Sakti and I recognize the importance of understanding the drone's hardware components comprehensively. As is customary in drone development, we need to prepare the following: </p>
                        <ul className='list-disc ml-4'>
                            <li >
                            Quadcopter frame; consider size, type of quad, and materials, etc.
                            </li>
                            <li >
                            Four motors
                            </li>
                            <li >
                            ESCs (Electronic Speed Controllers) 
                            </li>
                            <li >
                            Propellers: must be an exact match for the frame
                            </li>
                            <li >
                            Connectors
                            </li>
                            <li >
                            Flight battery / battery monitor (optional)
                            </li>
                            <li >
                            Mounting pad
                            </li>
                            <li >
                            Radio controller (RC) and receiver
                            </li>
                            <li >
                            Aerial camera
                            </li>
                            <li >
                            USB Key
                            </li>
                        </ul>
                        <br/>
                        <p className='text-lg'>Detailed explanations for each piece of equipment will be provided in the upcoming article. It's worth noting that the division responsible for utilizing these tools and equipment is mainly focused on mechanical and electrical design. As OpenCV programmers, Sakti and I will likely collaborate closely with camera tools. For this competition, we've chosen to use the Intel RealSense T265.</p>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"It was a hectic period, we need to learn a lot of new things."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-10 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict3, 'We directly tested our program using the T265 - Intel RealSense Camera.')}>
                            <img
                                src={Pict3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="We directly tested our program using the T265 - Intel RealSense Camera."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>We directly tested our program using the T265 - Intel RealSense Camera.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'><i>Thursday, 16 February 2023 at 09.58 WIB, </i>Sakti and I engaged in activities to calibrate the camera, construct the command schema, and determine the most suitable threshold for the drone. Through numerous experiments, we sought to discover the most effective and efficient formula to ensure flawless drone flight. Our experiments involved testing various ArUco sizes and packages to guarantee consistent readability by the drone. After extensive experimentation, we conceived the idea of utilizing sequential ArUco numbers. This approach ensures that the drone follows a predetermined order of ArUco IDs, preventing it from skipping any particular ArUco ID. This mitigates the risk of errors such as backward steps or getting stuck at a specific y-axis coordinate. Consequently, we defined available commands such as "go," "back," "left," and "right" without the necessity of diagonal movements. Since we were working with two drones, diagonal moves posed a significant risk of collision. As we progressed, we also observed that within a 1-meter y-axis, the T265 could read an ArUco in the range of 1.5-2 meters.</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-36 mb-20'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict4, 'Finally, we were able to test our program in a real environment.')}>
                            <img
                                src={Pict4}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Finally, we were able to test our program in a real environment."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Finally, we were able to test our program in a real environment.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi | Labtek 8, Institut Teknologi Bandung, Indonesia</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'><i>Monday, 20 March 2023 at 16.20 WIB, </i>we initiated our first real environment testing without any crashes, even though it required more than 16 hours (sacrificing my sleep hours). During this testing phase, we identified a few bugs that did not surface in our previous trials using Mission Planner or manual hand gesture moves with the camera. This is a common occurrence, as virtual testing environments like Mission Planner may not cover real-world conditions entirely. Consequently, we needed to reconfigure some of the drone's settings to ensure reliability in actual environmental conditions.</p>
                        <br/>
                        <p className='text-lg'>Upon revisiting the SAFMC's rules, we realized that there was a restriction against flying the drone more than 1 meter above the ground. To comply with SAFMC's terms and rules, we made several adjustments, such as changing the ArUco marker package, adjusting their size, exploring the use of another camera, and determining the most suitable camera positioning. In the end, we opted for the use of two cameras for each drone. The first camera was dedicated to the mapping task, while the other was utilized in the drone's decision-making process.</p>
                    </div>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict2})`, backgroundSize: 'cover', }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-36 mb-20'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict5, 'The drone was ready to flight!!')}>
                            <img
                                src={Pict5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The drone was ready to flight!!"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The drone was ready to <s>fight</s> flight!!</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi | Labtek 8, Institut Teknologi Bandung, Indonesia</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>SAFMC asked for all the participants to record their drone's progress and sent it to them before the D-Day competition. That's why, we do a really hardworks action before our flight date to Singapore. It needs a lot of dedication, because of every we made a mistake, then it will be a highly risk, cause the drone could be crash then we need to repair it again and again. FYI, the reparation time was not just a short-time, it needs a lot of times to repair it. So, be focus and detail is the key for this process. Just a few hours before our departures, we finally finished our drone and sent our drone's video to SAFMC. We are very grateful that we can catch up with the deadline. Now, we just need to prepare our departure to Singapore.</p>
                        <div className='article-break-1 flex justify-start items-center flex-col'>
                            <hr className='w-full mt-4 mb-4  border-t border-b border-solid'/>
                        </div>
                        <p className='text-lg font-bold'><i>to be continued...</i></p>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-8 mb-4'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"Finally we can finish this work, hopefully it will has a happy ending ☺"</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
            </div>
            <div className='flex justify-center items-center px-12 md:mb-8 mb-4 gap-x-8'>
                <p className='md:text-xl text-lg text-textGrey font-bold flex items-center'>
                    <FaAngleDoubleLeft className='w-3 h-3 text-textGrey inline-block ml-1' />
                    PREV 
                </p>
                <Link to="/safmc/safmc-part2" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    NEXT 
                    <FaAngleDoubleRight className='w-3 h-3 text-textRed inline-block ml-1' />
                </Link>
            </div>
            <div className='bg-fixed w-full h-96' style={{ backgroundImage: `url(${Pict5})`, backgroundSize: 'cover',  }}>
            </div>
            <CommentListExample postId={1}/>
            <ImageModal
                showModal={modalVisible}
                closeModal={closeModal}
                imageUrl={selectedImage}
                imageAlt={selectedAlt}
            />
            <Footer/>
            {arrowTransition((style, item) =>
                item ? (
                <animated.div
                    style={style}
                    className="fixed bottom-5 right-5 cursor-pointer z-10"
                    onClick={scrollToTop}
                >
                    <FaArrowCircleUp
                    size={isMd ? 32 : 24}
                    className="text-[#c0c5ca] hover:scale-110"
                    />
                </animated.div>
                ) : null
            )}
        </div>
    );
};

export default SAFMCPART1