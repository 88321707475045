import React, { useState } from 'react';
import { FaAngleDoubleUp, FaAngleDoubleDown, FaCheckCircle } from 'react-icons/fa';

interface Comment {
  id: number;
  username: string;
  date: string;
  time: string;
  text: string;
  parentId?: number | null;
  postId: number;
}

interface CommentProps {
  comment: Comment;
  onReplyClick?: () => void; // Callback for reply button click
  isReplying?: boolean;
  allComments: Comment[];
}

const Comment: React.FC<CommentProps> = ({ comment, onReplyClick, isReplying, allComments }) => {
  const [showReplies, setShowReplies] = useState(true);

  const toggleReplies = () => {
    setShowReplies((prevShowReplies) => !prevShowReplies);
  };

  const getReplies = () => {
    console.log("AllComment: " + allComments);
    const replies = allComments.filter((c) => c.parentId === comment.id);
    console.log("replies: " + replies);
    return replies;
  };

  return (
    <div className={`comment font-condensed drop-shadow-md max-w-4xl mx-auto bg-white p-2 rounded-md mb-2 ${isReplying ? 'border border-textRed' : ''}`}>
      <div className='flex justify-between'>
        <div className='flex flex-row'>
          <div className=' w-full h-auto'>
            <p className="md:text-sm text-xs text-left font-bold text-textGrey mr-1">
              {comment.username !== "Aremaniak1_" && (
                <>
                  {`${comment.username}`}
                  <span className="font-extralight">{` on ${comment.date} at ${comment.time}`}</span>
                </>
              )}
              {comment.username === "Aremaniak1_" && (
                <>
                  Mohammad Rifqi Farhansyah
                  <FaCheckCircle className='inline-block ml-0.5 text-textRed'></FaCheckCircle>
                  <span className="font-extralight">{` on ${comment.date} at ${comment.time}`}</span>
                </>
              )}
            </p>
            <p className="comment-text text-textGrey mt-2 text-left md:text-md text-xs overflow-hidden break-words mr-2">
              {comment.text}
            </p>
          </div>
        </div>
        {onReplyClick && (
          <button onClick={onReplyClick} className="h-1/2 py-2 px-4 reply-button bg-white text-textRed hover:bg-textRed hover:text-white rounded-md border border-textRed">
            Reply
          </button>
        )}
      </div>
      {getReplies().length > 0 && (
        <div className="replies mt-2 pl-4 border-l border-textGrey">
          <button onClick={toggleReplies} className="flex items-center text-xs text-textRed mb-2 cursor-pointer">
            {showReplies ? 'Hide Replies' : 'Show Replies'}
            {showReplies ? <FaAngleDoubleUp className="ml-1" /> : <FaAngleDoubleDown className="ml-1" />}
          </button>
          {showReplies && (
            <>
              {getReplies().map((reply) => (
                <Comment key={reply.id} comment={reply} allComments={allComments} />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Comment;
