// ReplyForm.tsx
import React, { useState, useEffect } from 'react';
import { FaRotate  } from 'react-icons/fa6';

interface ReplyFormProps {
  onCancelReply?: () => void;
  replyToComment?: number | null;
  postId: number;
}

const ReplyForm: React.FC<ReplyFormProps> = ({ onCancelReply, replyToComment, postId }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [mathQuestion, setMathQuestion] = useState<string>('');
  const [mathAnswer, setMathAnswer] = useState<number | null>(null);
  const [userAnswer, setUserAnswer] = useState('');
  const [expired, setExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const generateMathQuestion = () => {
    const operand1 = Math.floor(Math.random() * 10) + 1;
    const operand2 = Math.floor(Math.random() * 10) + 1;
    const operators = ['+', '-', '*'];
    const operator = operators[Math.floor(Math.random() * operators.length)];

    setMathAnswer(eval(`${operand1} ${operator} ${operand2}`));
    setMathQuestion(`${operand1} ${operator} ${operand2} = `);

    // Set a timer for 30 seconds (adjust as needed)
    const timer = setTimeout(() => {
      setExpired(true);
    }, 30000);
    
    // Clear the timer if the component unmounts or the form is submitted
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    generateMathQuestion();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api-rifqifarhansyah-id.onrender.com/api/comments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            PostID: postId,
            DatePost: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
            TimePost: `${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}`,
            ParentID: replyToComment,
            UserName: name,
            UserEmail: email,
            Content: comment,
        }),
      });
      const tanggal = new Date().toISOString().split('T')[0]
      console.log(tanggal)
      const waktu = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      console.log(waktu)

      if (response.ok) {
        // Reply submitted successfully
        console.log('Reply submitted successfully');
        setName('');
        setEmail('');
        setComment('');
        generateMathQuestion();
        setErrorMessage('');

        if (onCancelReply) {
          onCancelReply();
        }
      } else {
        // Error submitting reply
        console.error('Error submitting reply:', response.statusText);
        setErrorMessage('Error submitting reply. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting reply:', error);
      setErrorMessage('Error submitting reply. Please try again.');
    }
  };

  const handleCancel = () => {
    // Invoke the onCancelReply callback to close the form
    if (onCancelReply) {
      onCancelReply();
    }
  };

  const handleRefresh = () => {
    generateMathQuestion();
    setUserAnswer(''); // Clear the user's answer when refreshing
  };

  return (
    <div className="text-textGrey font-condensed max-w-2xl mx-auto my-8 p-6 bg-white">
      <h2 className="text-2xl font-semibold mb-1 text-left">Reply a Comment</h2>
      <p className='text-sm font-semibold mb-4 text-left'>Your email address will not be published. Required fields are marked *.</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm text-left font-medium text-textGrey">
            Name *
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your name will be displayed"
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-textRed focus:shadow shadow-md"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm text-left font-medium text-textGrey" placeholder='Just in case, I need to contact you further.'>
            Email *
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='In case I need to contact you'
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-textRed focus:shadow shadow-md"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="comment" className="block text-sm text-left font-medium text-textGrey"  placeholder='Write your comment here..'>
            Comment *
          </label>
          <textarea
            id="comment"
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={4}
            placeholder='Write your comment here..'
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-textRed focus:shadow shadow-md"
            required
          ></textarea>
        </div>
        {replyToComment !== null && (
          <input
            type="hidden"
            name="replyToComment"
            value={replyToComment}
          />
        )}
        <div className='flex md:flex-row flex-col md:items-center md:align-center justify-start'>
          <div className="">
            <div className="flex items-center">
              <span className="mr-2">{mathQuestion}</span>
              <input
                type="number"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                className="p-2 w-20 border border-gray-300 rounded-md focus:outline-none focus:border-textRed focus:shadow shadow-md"
                required
              />
              <button
                type="submit"
                className="px-2 py-2 text-textGrey hover:text-textRed hover:scale-110 focus:outline-none focus:shadow-outline-textRed active:scale-105"
              >
                <FaRotate className='inline-block ml-0.5' onClick={handleRefresh}></FaRotate>
              </button>
            </div>
          </div>
          <div className='flex flex-row md:gap-x-0 gap-x-2'>
            <div className="text-left md:ml-4 ml-0">
                <button
                type="submit"
                className="px-4 py-2 bg-white border border-textRed text-textRed rounded-xl hover:bg-textRed hover:text-white focus:outline-none focus:shadow-outline-textRed active:scale-105 md:mt-0 mt-2"
                >
                Submit
                </button>
            </div>
            {/* Cancel button */}
            <div className="text-left md:ml-4 ml-0">
                <button
                type="button" // Set the type to "button" to prevent form submission
                onClick={handleCancel}
                className="px-4 py-2 bg-white border border-textRed text-textRed rounded-xl hover:bg-textRed hover:text-white focus:outline-none focus:shadow-outline-textRed active:scale-105 md:mt-0 mt-2"
                >
                Cancel
                </button>
            </div>
          </div>
        </div>
      </form>
      {errorMessage && (
        <div className="text-left font-condensed text-textRed mt-2">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default ReplyForm;
