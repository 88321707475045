import React, { useEffect, useState } from 'react';
import { FaVolumeUp, FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import ProfileModal from './blogDetail/utils/profileModal.tsx';

import ProfileImage1 from '../assets/Rifqi1.webp';
import ProfileImage2 from '../assets/Rifqi2.webp';
import ProfileImage3 from '../assets/Rifqi3.webp';
import ProfileImage4 from '../assets/Rifqi4.webp';
import ProfileImage5 from '../assets/Rifqi5.webp';
import AudioFile from '../assets/Rifqi-Pronounced.mp3';

const Profile: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 67.5rem)').matches);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = React.createRef();
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedAlt, setSelectedAlt] = useState('');
    const [showBlogDropdownHover, setShowBlogDropdownHover] = useState<boolean>(false);
    const [hideTimeout, setHideTimeout] = useState<number | null>(null);
    const [hideTimeoutPP, setHideTimeoutPP] = useState<number | null>(null);
    const [showArrows, setShowArrows] = useState(false);
    const [intervalId, setIntervalId] = useState<number | null>(null);
    const [isHovering, setIsHovering] = useState(false);

    const openModal = (imageUrl, imageAlt) => {
        setSelectedImage(imageUrl);
        setSelectedAlt(imageAlt);
        setModalVisible(true);
    };

    const closeModal = () => {
        setSelectedImage('');
        setSelectedAlt('');
        setModalVisible(false);
    };

    const profileImages = [
        ProfileImage1,
        ProfileImage2,
        ProfileImage3,
        ProfileImage4,
        ProfileImage5,
    ];

    const captionImages = [
        "I said 'cheese' in front of Marina Bay Sands. | Merlion Park, Singapore | 2023 © Rifqi",
        "My first photo at ITB. | Institut Teknologi Bandung (G), Indonesia | 2021 © Rifqi",
        "Selected as the recipient of the Ganesha Award 2023. | Institut Teknologi Bandung (J), Indonesia | 2023 © Rifqi",
        "Presented my work in front of SAFMC 2023's judges. | Singapore Science Centre, Singapore | 2023 © Rifqi",
        "Won a bronze medal at SAFMC 2023. | SUTD, Singapore | 2023 © Rifqi",
    ];

    const toggleAudio = () => {
        if (isPlaying) {
            audioRef.current.currentTime = 0;
            audioRef.current.play();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleBlogDropdownEnter = () => {
        setShowBlogDropdownHover(true);
        clearTimeout(hideTimeout as number);
    };

    const handleBlogDropdownLeave = () => {
        const timeoutId = window.setTimeout(() => {
            setShowBlogDropdownHover(false);
        }, 50);
        setHideTimeout(timeoutId);
    };

    const handleNextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % profileImages.length);
        resetInterval()
    };

    const handlePreviousImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + profileImages.length) % profileImages.length);
        resetInterval()
    };

    const handleMouseEnter = () => {
        setShowArrows(true);
        setIsHovering(true);
        clearTimeout(hideTimeoutPP as number);
    };
    
    const handleMouseLeave = () => {
        setShowArrows(false);
        const timeoutIdPP = window.setTimeout(() => {
            setIsHovering(false);
        }, 500);
        setHideTimeoutPP(timeoutIdPP);
    };
    

    const resetInterval = () => {
        clearInterval(intervalId as number);
        const newIntervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % profileImages.length);
        }, 5000);
        setIntervalId(newIntervalId);
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 67.5rem)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    useEffect(() => {
        clearInterval(intervalId);
    
        const newIntervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % profileImages.length);
        }, 5000);
        setIntervalId(newIntervalId);
    
        return () => clearInterval(newIntervalId);
    }, [profileImages.length]);
    

    return(
        <div className='flex flex-col bg-lightGrey items-center'  id="profile">
            {isMd ? (
            <div className='flex flex-row items-start justify-center pt-10 pb-10 w-[65.625rem] h-auto mx-10'>
                <div className='flex flex-col items-left mt-36 relative'>
                    <div className={`hover-dropdown absolute text-left mt-14 bg-white border-t-2 drop-shadow-md border-textRed rounded-md py-2 transition-all duration-300 ease-in-out ${showBlogDropdownHover ? 'opacity-100 translate-y-8' : 'opacity-0 translate-y-16'}`}>
                        <span className="md:block hidden px-4 py-2 text-gray-800 duration-500 font-condensed font-regular text-sm text-justify">
                        I am from <span className='text-textRed font-bold'>Indonesia</span> and grew up on the island of Java, same Java that inspired one of the most popular programming language's name! Therefore, in addition to be <span className='font-bold'>fluent in Java</span>, I am also <span className='font-medium'>proficient in Javanese</span>.
                        </span>
                    </div>
                    <p className='font-condensed text-4xl text-left font-extrabold'>
                        M. <span className='text-textRed cursor-pointer' onMouseEnter={handleBlogDropdownEnter} onMouseLeave={handleBlogDropdownLeave}>RIFQI</span> <br />FARHANSYAH
                    </p>
                    <hr className='w-28 h-0.5 bg-textRed mt-2' />
                    <p className='text-md text-left font-thin mt-2 font-condensed'>
                        <i>"Not everything that counts can<br/> be counted, and not everything<br/> that can be counted counts."</i>
                    </p>
                </div>
                <div className='flex flex-col'>
                    <div className='w-96 h-auto ml-2 mt-8'>
                        {profileImages.map((image, index) => (
                            <div
                                key={index}
                                className="relative"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                >
                                <img
                                    className='w-96 h-auto ml-5 cursor-pointer'
                                    src={image}
                                    alt='Rifqi Profile'
                                    title='Look at my photos! :)'
                                    width="90"
                                    height="90"
                                    style={{
                                        opacity: currentIndex === index ? 1 : 0,
                                        transition: 'opacity 0.3s ease-in-out',
                                        position: 'absolute',
                                    }}
                                    onClick={() => openModal(profileImages[currentIndex], captionImages[currentIndex])}
                                />
                                {currentIndex === index && (
                                    <>
                                        <div className={`absolute ml-7 mt-48 top-1/2 transform -translate-y-1/2 left-0 z-10 transition-opacity duration-200 ease-in-out ${showArrows ? 'opacity-100' : 'opacity-0'}`}>
                                            <button className="bg-white bg-opacity-30 p-2 rounded-full hover:scale-105" onClick={handlePreviousImage} title='Go to previous image'>
                                                <FaChevronCircleLeft className="text-white" />
                                            </button>
                                        </div>
                                        <div className={`absolute -mr-3 mt-48 top-1/2 transform -translate-y-1/2 right-0 z-10 transition-opacity duration-200 ease-in-out ${showArrows ? 'opacity-100' : 'opacity-0'}`}>
                                            <button className="bg-white bg-opacity-30 p-2 rounded-full hover:scale-105" onClick={handleNextImage} title='Go to next image'>
                                                <FaChevronCircleRight className="text-white" />
                                            </button>
                                        </div>
                                    </>
                                )}
                                {/* Circles */}
                                <div className="absolute flex items-center justify-center bottom-0 w-full mb-4 ml-5 top-96 pt-4">
                                    {profileImages.map((_, idx) => (
                                        <div
                                            key={idx}
                                            className={`w-2 h-2 mx-1 rounded items-center justify-center rounded-full cursor-pointer hover:scale-150 ${currentIndex === idx ? 'bg-textRed' : 'bg-gray-400 hover:bg-textGrey'}`}
                                            onClick={() => {
                                                resetInterval();
                                                setCurrentIndex(idx);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <h2 className='font-bold font-condensed text-left text-textGrey ml-16 relative' style={{ marginTop: "27.2rem" }} >
                        <a href="mailto:mrifqifarhansyah@gmail.com" className="hover:text-textRed">Email:</a>
                        <p className='ml-3 font-thin'>
                            m[<span className='italic'> middle-name </span>]farhansyah@gmail.com
                        </p>
                    </h2>
                    <h2 className='font-bold font-condensed text-left text-textGrey ml-16 relative'>
                        <a href="https://www.google.com/maps?q=-6.888670874762966,107.62826809096634" className="hover:text-textRed">Office:</a>
                        <p className='ml-3 font-thin'>
                            Jl. Cibeunying Permai I, no17B<br/> Cigadung, Cibeunying Kaler 40191, Bandung
                        </p>
                    </h2>
                </div>
                <div className='text-lg text-center max-w-md ml-10 font-condensed mt-14'>
                    <p className='font-extralight text-left text-textGrey'>
                        <span className='font-bold'> Rifqi </span> ( pronounced: /'rɪf ki:/ ){' '}
                        <button onClick={toggleAudio} title='Rifqi-Pronounced'>
                        <span className='align-middle mr-2 hover:text-textRed inline-block'>
                            <FaVolumeUp />
                        </span>
                        </button>
                        <audio ref={audioRef} src={AudioFile} />
                        is a final-year <span className='text-textRed font-bold'>Computer Science student</span><a href="https://www.itb.ac.id/" className='hover:text-textRed'> at Institut Teknologi Bandung (<span className='text-textRed font-bold'>ITB</span>)</a>. He is <span className='font-bold'>interested in research</span>, specifically about <span className='font-bold'>Machine Learning</span> Application and Specific Fields, <span className='font-bold'>Natural Language Processing</span>, <span className='font-bold'>Large Language Models</span>, and <span className='font-bold'>Low Resource NLP</span>. He believes these topics will play an important role in the future. <br/><br/>
                    </p>
                    <p className='font-extralight text-left text-textGrey'>Currently, He is working on projects related to <span className='font-bold'>Large Language Models </span> and <span className='font-bold'>Natural Language Processing</span> with <span className='text-textRed font-semibold  hover:underline'><a href='https://derrywijaya.github.io/web/'>Prof. Derry Wijaya, B.Sc., M.Sc., Ph.D.</a></span> (Assistant Professor at Computer Science Department of Boston University, MA, USA),  <span className='text-textRed font-semibold  hover:underline'><a href='https://afaji.github.io/'>Dr. Alham Fikri Aji, S.T., M.Sc., Ph.D.</a></span> (Assistant Professor at Mohamed bin Zayed University of Artificial Intelligence, UAE), and <span className='text-textRed font-semibold  hover:underline'><a href='https://www.linkedin.com/in/ayu-purwarianti/?originalSubdomain=id/'>Dr. Eng. Ayu Purwarianti, S.T., M.T.</a></span> (Associate Professor at Institut Teknologi Bandung, Indonesia). He also actively involved in <span className='text-textRed'>Science and Computational Engineering Laboratory</span> as Teaching Assistant under the supervision of <span className='text-textRed font-bold  hover:underline'><a href='https://informatika.stei.itb.ac.id/~rinaldi.munir/'>Dr. Ir. Rinaldi Munir, M.T.</a></span></p><br/>
                    {/* <p className='font-extralight text-left text-textGrey'>He is an enthusiastic and passionate individual seeking a <span className='text-textRed'>research collaboration project</span>. Please feel free to contact him if you share similar interests.</p><br/> */}
                </div>
            </div>
            ) : (
            <div className='flex flex-col items-center mt-5 font-condensed w-full h-auto'>
                <p className='text-xl font-condensed text-left text-center font-extrabold pt-3' id="profile">
                M. <span className='text-textRed'>RIFQI</span> FARHANSYAH
                </p>
                <div className='w-60 h-auto mt-2 relative'>
                    {profileImages.map((image, index) => (
                            <div
                                key={index}
                                className="relative"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                >
                                <img
                                    className='w-60 h-auto cursor-pointer'
                                    src={image}
                                    alt='Rifqi Profile'
                                    title='Look at my photos! :)'
                                    width="60"
                                    height="60"
                                    style={{
                                        opacity: currentIndex === index ? 1 : 0,
                                        transition: 'opacity 0.3s ease-in-out',
                                        position: 'absolute',
                                    }}
                                    onClick={() => openModal(profileImages[currentIndex], captionImages[currentIndex])}
                                />
                                {currentIndex === index && (
                                    <>
                                        <div className='absolute ml-2 top-1/2 transform -translate-y-1/2 left-0 z-10' style={{ marginTop: "7.5rem" }}>
                                            <button className="bg-white bg-opacity-30 p-1 rounded-full text-sm" onClick={handlePreviousImage} title='Go to previous image'>
                                                <FaChevronCircleLeft className="text-white" />
                                            </button>
                                        </div>
                                        <div className='absolute mr-2 top-1/2 transform -translate-y-1/2 right-0 z-10' style={{ marginTop: "7.5rem" }}>
                                            <button className="bg-white bg-opacity-30 p-1 rounded-full text-sm" onClick={handleNextImage} title='Go to next image'>
                                                <FaChevronCircleRight className="text-white" />
                                            </button>
                                        </div>
                                    </>
                                )}
                                {/* Circles */}
                                <div className="absolute flex items-center justify-center bottom-0 w-full mb-4 top-60 mt-3">
                                    {profileImages.map((_, idx) => (
                                        <div
                                            key={idx}
                                            className={`w-1.5 h-1.5 mx-1 rounded items-center justify-center rounded-full cursor-pointer ${currentIndex === idx ? 'bg-textRed' : 'bg-gray-400'}`}
                                            onClick={() => {
                                                resetInterval();
                                                setCurrentIndex(idx);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                <p className='text-xs font-thin mt-[16.5rem] mb-5'>
                <i>"Not everything that counts can be counted, <br/>and not everything that can be counted counts."</i>
                </p>
                <div className='text-center ml-10 mr-10 pb-10 font-condensed'>
                <p className='font-extralight text-left text-textGrey'> <span className='font-bold'> Rifqi </span> ( pronounced: /'rɪf ki:/ )
                    <button onClick={toggleAudio} title='Rifqi-Pronounced'>
                    <span className='align-middle ml-0.5 mr-1 hover:text-textRed align-middle inline-block'>
                        <FaVolumeUp />
                    </span>
                    </button>
                    <audio ref={audioRef} src={AudioFile} />
                    is a final-year <span className='text-textRed font-bold'>Computer Science student</span><a href="https://www.itb.ac.id/" className='hover:text-textRed'> at Institut Teknologi Bandung (<span className='text-textRed font-bold'>ITB</span>)</a>. He is <span className='font-bold'>interested in research</span>, specifically about <span className='font-bold'>Machine Learning</span> Application and Specific Fields, <span className='font-bold'>Natural Language Processing</span>, <span className='font-bold'>Large Language Models</span>, and <span className='font-bold'>Low Resource NLP</span>. He believes these topics will play an important role in the future. <br/><br/>
                </p>
                <p className='font-extralight text-left text-textGrey'>Currently, He is working on projects related to <span className='font-bold'>Large Language Models </span> and <span className='font-bold'>Natural Language Processing</span> with <span className='text-textRed font-semibold  hover:underline'><a href='https://derrywijaya.github.io/web/'>Prof. Derry Wijaya, B.Sc., M.Sc., Ph.D.</a></span> (Assistant Professor at Computer Science Department of Boston University, MA, USA),  <span className='text-textRed font-semibold  hover:underline'><a href='https://afaji.github.io/'>Dr. Alham Fikri Aji, S.T., M.Sc., Ph.D.</a></span> (Assistant Professor at Mohamed bin Zayed University of Artificial Intelligence, UAE), and <span className='text-textRed font-semibold  hover:underline'><a href='https://www.linkedin.com/in/ayu-purwarianti/?originalSubdomain=id/'>Dr. Eng. Ayu Purwarianti, S.T., M.T.</a></span> (Associate Professor at Institut Teknologi Bandung, Indonesia). He also actively involved in <span className='text-textRed'>Science and Computational Engineering Laboratory</span> as Teaching Assistant under the supervision of <span className='text-textRed font-bold  hover:underline'><a href='https://informatika.stei.itb.ac.id/~rinaldi.munir/'>Dr. Ir. Rinaldi Munir, M.T.</a></span></p><br/>
                {/* <p className='font-extralight text-left text-textGrey'>He is an enthusiastic and passionate individual seeking a <span className='text-textRed'>research collaboration project</span>. Please feel free to contact him if you share similar interests.</p><br/> */}
                <div className=''>
                    <h2 className='font-bold font-condensed text-left text-textGrey'>
                    <a href="mailto:mrifqifarhansyah@gmail.com" className="hover:text-textRed">Email:</a>
                    <p className='ml-3 font-thin'>
                        m[<span className='italic'> middle-name </span>]farhansyah@gmail.com
                    </p>
                    </h2>
                    <h2 className='font-bold font-condensed text-left text-textGrey'>
                    <a href="https://www.google.com/maps?q=-6.888670874762966,107.62826809096634" className="hover:text-textRed">Office:</a>
                    <p className='ml-3 font-thin'>
                        Jl. Cibeunying Permai I, no17B<br/> Cigadung, Cibeunying Kaler 40191, Bandung
                    </p>
                    </h2>
                </div>
                </div>
            </div>
            )}
            <ProfileModal
                showModal={modalVisible}
                closeModal={closeModal}
                imageUrl={selectedImage}
                imageAlt={selectedAlt}
            />
        </div>
    );
};

export default Profile