import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTransition, animated } from 'react-spring';
import { FaArrowCircleUp, FaAngleDoubleLeft, FaAngleDoubleRight, FaEye } from 'react-icons/fa';
import NavbarDetailBlog from '../../../components/navbarDetailBlog.tsx';
import HeaderDetailBlog from '../utils/headerDetailBlog.tsx';
import Footer from '../../../components/footer.tsx';
import { Link } from 'react-router-dom';
import ImageModal from '../utils/imageModal.tsx';
import CommentListExample from '../../../components/comments/commentListExample.tsx';


import usePreventZoom from '../../../preventZoom.js';

import Header from '../../../assets/Blog/SAFMC/SAFMC_PART6/Detail/HEADER_DETAIL_SAFMC_PART6.webp';
import Pict1 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Detail/1.webp";
import Pict2 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Detail/2.webp";
import Pict3 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Detail/3.webp";
import Pict4 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Detail/4.webp";
import Pict5 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Detail/5.webp";
import Pict6 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Detail/6.webp";
import Pict7 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Detail/7.webp";

import Gallery1 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/1.webp";
import Gallery2 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/2.webp";
import Gallery3 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/3.webp";
import Gallery4 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/4.webp";
import Gallery5 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/5.webp";
import Gallery6 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/6.webp";
import Gallery7 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/7.webp";
import Gallery8 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/8.webp";
import Gallery9 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/9.webp";
import Gallery10 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/10.webp";
import Gallery11 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/11.webp";
import Gallery12 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/12.webp";
import Gallery13 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/13.webp";
import Gallery14 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/14.webp";
import Gallery15 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/15.webp";
import Gallery16 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/16.webp";
import Gallery17 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/17.webp";
import Gallery18 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/18.webp";
import Gallery19 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/19.webp";
import Gallery20 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/20.webp";
import Gallery21 from "../../../assets/Blog/SAFMC/SAFMC_PART6/Gallery/21.webp";

const SAFMCPART6: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 900px)').matches);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedAlt, setSelectedAlt] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(30);
        setTimeout(() => {
            setProgress(100);
        }, 600);
    }, []);
  
    const openModal = (imageUrl, imageAlt) => {
        setSelectedImage(imageUrl);
        setSelectedAlt(imageAlt);
        setModalVisible(true);
    };
  
    const closeModal = () => {
        setSelectedImage('');
        setSelectedAlt('');
        setModalVisible(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 900px)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 350) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const arrowTransition = useTransition(showScrollToTop, {
        from: { opacity: 0, transform: 'translateY(100%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(100%)' },
        config: { tension: 300, friction: 20 },
      });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: false});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    usePreventZoom();

    return(
        <div className='App'>
            <NavbarDetailBlog/>
            <div className='pt-20 min-w-screen font-condensed text-textGrey'>
                {/* Loading Bar */}
                <LoadingBar color="#8C001f" progress={progress} onLoaderFinished={() => setProgress(0)} />
                <section className='hidden w-64 mr-10 group transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out'>
                        <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=e1e1e1&w=a&t=tt&d=mIBEHA96Sa9wc29y0yefyCb9wP8YgephePOwDaQRDk0&co=ffffff&cmo=8c001f&cmn=d4201e&ct=394048'></script>
                </section>
                <HeaderDetailBlog backgroundImage={Header} title="Walk Around Singapore - Hello Merlion!" date="24" year="December, 2023" backgroundPosition="center bottom"></HeaderDetailBlog>
                <div className='author-article text-end pr-4 pt-8 pb-4 text-xs'>
                    <p className='font-condensed text-textGrey'>by Mohammad Rifqi Farhansyah | Singapore Amazing Flying Machine Competition 2023</p>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict1, "Start our 'Refreshing Session' in Singapore.")}>
                            <img
                                src={Pict1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Start our 'Refreshing Session' in Singapore."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Start our 'Refreshing Session' in Singapore.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-2xl'>Singapore today feels different from the Singapore I visited a few days ago. Perhaps it's because the pressure of the competition is no longer weighing on us. I continue to imagine exploring the beautiful corners of Singapore.</p>
                        <br/>
                        <p className='text-lg'>On Friday, March 31, 2023, we arrived once again in Singapore for a day of recreational activities. We reached Singapore at 11:00 AM local time, using the ferry as usual. Our initial destination was Merlion Park and Marina Bay Sands, iconic landmarks that are essential stops for tourists in Singapore. Subsequently, we planned to visit the nearest mosque for Jum'ah prayer. Following that, we intended to explore the Singapore National Museum for some shopping. Our journey would conclude at Mustafa Store, a renowned souvenir center in Singapore.</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery1, "Singapore's view (Part 1).")}>
                            <img
                                src={Gallery1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 1)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 1).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery2, "Singapore's view (Part 2).")}>
                            <img
                                src={Gallery2}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 2)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 2).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery3, "Singapore's view (Part 3).")}>
                            <img
                                src={Gallery3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 3)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 3).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"Singapore was so amazing. There were a lot of things I had to explore. I believe today would be astonishing."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery4, "Singapore's view (Part 4).")}>
                            <img
                                src={Gallery4}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 4)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 4).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery5, "Singapore's view (Part 5).")}>
                            <img
                                src={Gallery5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 5)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 5).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery6, "Singapore's view (Part 6).")}>
                            <img
                                src={Gallery6}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 6)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 6).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict2})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict3, "Stay peace :)")}>
                            <img
                                src={Pict3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Stay peace :)"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Stay peace :)</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>We discovered three unique things while around Merlion Park. Firstly, someone suddenly recognized us as a group from ITB because I was wearing a "Jacket of the Student Association, HMIF ITB." This person turned out to be an alumni of the Department of Informatics, ITB, now working at a startup in Singapore. Secondly, I learned that Friday prayers are conducted in two sessions in Singapore. This is due to the limited prayer space, which is not proportional to the number of worshipers. We joined the second Friday prayer session, with the sermon starting at 2:00 PM local time. The last unique thing was that in every mosque in Singapore, we always found several bottles of perfume at the entrance. This may seem like a small thing, but it has a significant impact, and mosques in Indonesia might consider adopting this practice.</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery7, "Singapore's view (Part 7).")}>
                            <img
                                src={Gallery7}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 7)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 7).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery8, "Singapore's view (Part 8).")}>
                            <img
                                src={Gallery8}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 8)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 8).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery9, "Singapore's view (Part 9).")}>
                            <img
                                src={Gallery9}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 9)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 9).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"As tall as the buildings in these photos, we hold extremely high hopes for our achievements at tomorrow's SAFMC awarding ceremony."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery10, "Singapore's view (Part 10).")}>
                            <img
                                src={Gallery10}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 10)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 10).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery11, "Singapore's view (Part 11).")}>
                            <img
                                src={Gallery11}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 11)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 11).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery12, "Singapore's view (Part 12).")}>
                            <img
                                src={Gallery12}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 12)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 12).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict4})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict5, "The picture was described by the article's title.")}>
                            <img
                                src={Pict5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Was described by the article's title."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The picture was described by the article's title.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>After completing our prayers at Moulana Mohammed Ali Mosque, we continued our journey to the National Library located on Orchard Road. There, we spotted a legendary grandfather who seemed to be an icon of Orchard Road. Yes, indeed, he was the elderly gentleman selling ice cream sandwiches who had become a legend. Unfortunately, we were fasting at that time, so we didn't have the opportunity to taste the legendary ice cream sandwich on Orchard Road.</p>
                        <br/>
                        <p className='text-lg'>We continued our journey to the renowned souvenir shop, Mustafa Store. Before entering the store, our bag was secured with a cable tie to ensure mutual security between buyers and sellers within the store and among buyers themselves. We bought quite a lot of items there. However, the most significant purchases for me were seven T-shirts and several keychains featuring the Merlion statue. Meanwhile, my friends bought various food items, such as chocolates. I was worried that our bags would be overloaded due to the abundance of souvenirs we were carrying.</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery13, "Singapore's view (Part 13).")}>
                            <img
                                src={Gallery13}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 13)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 13).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery14, "Singapore's view (Part 14).")}>
                            <img
                                src={Gallery14}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 14)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 14).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery15, "Singapore's view (Part 15).")}>
                            <img
                                src={Gallery15}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 15)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 15).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"I really love this country. The people are incredibly productive, yet their productivity doesn't make them indifferent to others and their surroundings."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery16, "Singapore's view (Part 16).")}>
                            <img
                                src={Gallery16}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 16)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 16).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery17, "Singapore's view (Part 17).")}>
                            <img
                                src={Gallery17}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 17)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 17).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery18, "Singapore's view (Part 18).")}>
                            <img
                                src={Gallery18}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore's view (Part 18)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore's view (Part 18).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict6})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict7, "The 'SAFMC' boyband.")}>
                            <img
                                src={Pict7}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The 'SAFMC' boyband."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The 'SAFMC' boyband.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>Actually, these photos were taken this morning as we were about to depart from Batam to Singapore. However, it seems fitting to include these pictures as a conclusion to this article. They showcase the beauty of the port we traversed before reaching Singapore. Moreover, tomorrow we will once again arrive in Singapore using the same mode of transportation.</p>
                        <div className='article-break-1 flex justify-start items-center flex-col'>
                            <hr className='w-full mt-4 mb-4  border-t border-b border-solid'/>
                        </div>
                        <p className='text-lg font-bold'><i>to be continued...</i></p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery19, "Harbour Front's view (Part 1).")}>
                            <img
                                src={Gallery19}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Harbour Front's view (Part 1)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Harbour Front's view (Part 1).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery20, "Harbour Front's view (Part 2).")}>
                            <img
                                src={Gallery20}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Harbour Front's view (Part 2)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Harbour Front's view (Part 2).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery21, "Harbour Front's view (Part 3).")}>
                            <img
                                src={Gallery21}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Harbour Front's view (Part 3)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Harbour Front's view (Part 3).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-8 mb-4'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"Singapore, please bring us good news for tomorrow."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
            </div>
            <div className='flex justify-center items-center px-12 md:mb-8 mb-4 gap-x-8'>
                <Link to="/safmc/safmc-part5" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    <FaAngleDoubleLeft className='w-3 h-3 text-rgba-28-5-179 inline-block mr-1' />
                    PREV 
                </Link>
                <Link to="/safmc/safmc-part7" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    NEXT 
                    <FaAngleDoubleRight className='w-3 h-3 text-rgba-28-5-179 inline-block ml-1' />
                </Link>
            </div>
            <div className='bg-fixed w-full h-96' style={{ backgroundImage: `url(${Gallery20})`, backgroundSize: 'cover',  }}>
            </div>
            <CommentListExample postId={6}/>
            <ImageModal
                showModal={modalVisible}
                closeModal={closeModal}
                imageUrl={selectedImage}
                imageAlt={selectedAlt}
            />
            <Footer/>
            {arrowTransition((style, item) =>
                item ? (
                <animated.div
                    style={style}
                    className="fixed bottom-5 right-5 cursor-pointer z-10"
                    onClick={scrollToTop}
                >
                    <FaArrowCircleUp
                    size={isMd ? 32 : 24}
                    className="text-[#c0c5ca] hover:scale-110"
                    />
                </animated.div>
                ) : null
            )}
        </div>
    );
};

export default SAFMCPART6