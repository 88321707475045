// CommentListExample.tsx
import React, { useState, useEffect } from 'react';
import CommentList from './commentList.tsx';
import Comment from './comment.tsx';
import CommentForm from '../commentForm.tsx';
import io from 'socket.io-client'; // Import Socket.io-client

interface CommentListExampleProps {
  postId: number;
}

const CommentListExample: React.FC<CommentListExampleProps> = ({ postId }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(null);

  const handleReplyClick = (commentId: number) => {
    setSelectedCommentId(commentId);
  };

  // Socket.io setup
  const socket = io(`https://api-rifqifarhansyah-id.onrender.com`, {
    forceNew: true,
    transports: ['websocket', 'polling'],
  });

  socket.on('connect', () => {
    console.log('Socket connected:', socket.id);
  });

  useEffect(() => {
    // Fetch comments for a specific PostID from the server
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api-rifqifarhansyah-id.onrender.com/api/comments/${postId}`);
        const data = await response.json();

        const mappedComments = data.map((comment) => ({
          id: comment.CommentID,
          username: comment.UserName,
          date: comment.DatePost.split('T')[0],
          time: comment.TimePost.split(':00')[0],
          text: comment.Content,
          parentId: comment.ParentID,
          postId: comment.PostID,
          replies: [],
        }));

        setComments(mappedComments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchData();
  }, [postId]);

  useEffect(() => {
    // Event listener for new comments from Socket.io
    socket.on('message', (message) => {
      console.log('Received message:', message);
      const data = JSON.parse(message);

      if (data.action === 'newComment') {
        // Fetch the new comment from the server and update the state
        const fetchComment = async () => {
          try {
            const response = await fetch(`https://api-rifqifarhansyah-id.onrender.com/api/comments/${postId}`);
            const data = await response.json();
    
            const mappedComments = data.map((comment) => ({
              id: comment.CommentID,
              username: comment.UserName,
              date: comment.DatePost.split('T')[0],
              time: comment.TimePost.split(':00')[0],
              text: comment.Content,
              parentId: comment.ParentID,
              postId: comment.PostID,
              replies: [],
            }));
    
            setComments(mappedComments);
          } catch (error) {
            console.error('Error fetching new comment:', error);
          }
        };

        fetchComment();
      }
    });

    return () => {
      // Cleanup the Socket.io connection on component unmount
      socket.disconnect();
    };
  }, [postId, socket]);

  return (
    <div className='min-w-screen'>
      <h1 className='text-textGrey mt-8 font-condensed font-extrabold md:text-4xl text-2xl'>
        {comments.length} Comments
      </h1>
      <p className='text-textGrey font-condensed md:text-lg text-sm px-4'>
        Leave your thoughts, feedback, or anything else you'd like to share.
      </p>
      <div className='article-break-1 flex justify-center items-center flex-col md:mb-8 mb-4'>
        <hr className='w-5/6 mt-4 md:mb-8 mb-4 border-t border-b border-solid' />
      </div>
      <CommentList comments={comments} onReplyClick={handleReplyClick} selectedCommentId={selectedCommentId} postId={postId} />

      {/* Render CommentForm only when there is no active ReplyForm */}
      {!selectedCommentId && <CommentForm postId={postId} />}
    </div>
  );
};

export default CommentListExample;